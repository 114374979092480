/**
 * Created by mateimisarca on 2019-06-07
 */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';

import { courses } from '../../constants/courses';

import logo from './../../assets/images/cursuri-digitale-logo2-07.jpg';

export default class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="footer-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-6">
                <div className="footer-wedget-one">
                  <h2>Despre noi</h2>
                  <p>Cursuridigitale.ro este platformă online care oferă cursuri gratuite cadrelor didactice din
                    învățământul preuniversitar, pentru creşterea competenţelor digitale. </p>
                  <Link to="/"><img src={ logo } alt="" style={ { width: '70%' } } /></Link>
                </div>
              </div>

              <div className="col-md-3 col-sm-6">
                <div className="footer-widget-menu">
                  <h2>Cursurile noastre</h2>
                  <ul>
                    { courses.map((course, index) => (
                      <li key={ index }><Link to={ `/cursuri/${ course.link }` }>{ capitalize(course.title) }</Link>
                      </li>
                    )) }
                  </ul>
                </div>
              </div>

              <div className="col-md-3 col-sm-6">
                <div className="footer-widget-menu">
                  <h2>Informatii utile</h2>
                  <ul>
                    <li><Link to="/termeni-si-conditii">Termeni, condiții și politică de confidențialitate</Link></li>
                  </ul>
                </div>
              </div>

              <div className="col-md-3 col-sm-6">
                <div className="footer-wedget-four">
                  <h2>Contact</h2>
                  <div className="inner-box">

                    <div className="media">
                      <div className="inner-item">
                        <div className="media-left">
                          <span className="icon"><i className="fa fa-envelope-o" /></span>
                        </div>
                        <div className="media-body">
                          <span className="inner-text">
                            <a href="mailto:admin@cursuridigitale.ro" target="_top"
                               style={ { color: 'white' } }>admin@cursuridigitale.ro</a>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="media">
                      <div className="inner-item">
                        <div className="media-left">
                          <span className="icon"><i className="fa fa-phone" /></span>
                        </div>
                        <div className="media-body">
                          <span className="inner-text">

                            <a href="tel:0040374833500" style={ { color: 'white' } }>0374-833-500</a>
                            <br />
                            <a href="tel:0040752548372" style={ { color: 'white' } }>0752-548-372</a>

                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="media">
                      <div className="inner-item">
                        <div className="media-left">
                          <span className="icon"><i className="fa fa-envelope-o" /></span>
                        </div>
                        <div className="media-body">
                          <span className="inner-text">
                            <a href="https://forms.gle/LiX7cy5Pna2qtQpQ7" target="_blank"
                               style={ { color: 'white' } }>Contactează un formator</a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="copy-right">
                  <p>Copyright 2020 &copy; <span>Educational Digital</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </footer>
    );
  }
}
