/**
 * Created by mateimisarca on 2019-06-11
 */

import incheiat from '../assets/images/bullet-incheiat.png';
import activ from '../assets/images/bullet-activ.png';
import curand from '../assets/images/bullet-curand.png';
import inscriereActiva from '../assets/images/bullet-inscriere-activa.png';
import inactiv from '../assets/images/bullet-inactiv.png';
import inPregatire from '../assets/images/bullet-in-pregatire.png';

import curs1 from './../assets/images/banner_420x230_1.jpg';
import curs2 from './../assets/images/banner_420x230_2.jpg';
import curs3 from './../assets/images/banner_420x230_3.jpg';
import curs4 from './../assets/images/banner_420x230_4.jpg';
import curs5 from './../assets/images/banner_420x230_5.jpg';
import curs6 from './../assets/images/banner_420x230_6.jpg';
import curs7 from './../assets/images/banner_420x230_7.jpg';
import curs8 from './../assets/images/banner_420x230_8.jpg';

import curs9 from './../assets/images/25 de moduri de a crește puterea creierului.png';
import curs15 from './../assets/images/Comunicarea aici si acum.png';
import curs12 from './../assets/images/Copii care cooperează.png';
import curs10 from './../assets/images/Cum să predai cu încredere în timpuri dificile.png';
import curs16 from './../assets/images/Cum să te reinventezi în vremuri incerte.png';
import curs14 from './../assets/images/Exersează-ți memoria.png';
import curs13 from './../assets/images/Pașii către o viață echilibrată și de succes.png';
import curs11 from './../assets/images/Programarea Neuro-Lingvistică.png';

import bannerCursDictie from './../assets/images/banner_1920x350-4.jpg';
import banner from './../assets/images/banner_1920x350-1.jpg';

export const courses = [
  {
    _id: "616e5f6b1455141296c18814",
    title: 'Strategii de prevenire a bullyingului',
    texts: [
      {
        title: 'ORGANIZATOR SI PARTENERI',
        text: 'Cursul este avizat de Ministerul Educației și dezvoltat în parteneriat cu Casa Corpului Didactic București.' +
          'Înscrierea și participarea la curs sunt gratuite',
      },
      {
        title: 'CUI SE ADRESEAZĂ',
        text: 'Tuturor cadrelor didactice din învățământul preuniversitar.',
      },
    ],
    details: [
      {
        title: 'CUI SE ADRESEAZĂ',
        text: 'Tuturor cadrelor didactice din învățământul preuniversitar.',
      },
      {
        title: 'SCOP',
        text: 'Cursul are ca scop Abilitarea cadrelor didactice din învățământul preuniversitar pentru prevenirea bullyingului.',
      },
      {
        title: 'DURATĂ',
        text: 'Cursul are o durată de 24 de ore și se va desfășura în sistem blended learning: 6 ore webinar sincron și 18 ore online individual. După parcurgerea cu succes a tuturor modulelor, profesorii vor primi adeverințe emise de Casa Corpului Didactic București, care vor servi la completarea dosarului de formare profesională a cadrelor didactice.',
      },
      {
        title: 'COMPETENȚE VIZATE',
        text: '<ul>' +
          '<li>Cunoașterea caracteristicilor specifice ale bullyingului;</li>' +
          '<li>Conștientizarea efectelor bullyingului la nivelul individului și al comunității;</li>' +
          '<li>Identificarea modalităților de prevenire a bullyingului.</li>' +
          '</ul>',
      },
      {
        title: 'CALENDAR',
        text: '<b>1-15 februarie 2023</b>:  Înscrierea la curs<br />' +
          '<b>20-24 februarie 2023</b>: repartizarea pe serii pentru întâlnirile sincron <br />' +
          '*Cursul se va organiza pentru o grupă de minimum 25 de cadre didactice înscrise.',
      },
      {
        title: 'ORGANIZATOR SI PARTENERI',
        text: 'Cursul este avizat de Ministerul Educației și dezvoltat în parteneriat cu Casa Corpului Didactic București. <br />' +
          'Înscrierea și participarea la curs sunt gratuite',
      },
    ],
    durata: '24 ore',
    link: 'bullying',
    detalii: {
      trainer: '',
      materiale: '',
      teste: '',
      perioadaInscriere: '<div>1-28 februarie 2023</div>',
      locuri: '500',
      durata: '24 ore',
      pret: 'gratuit',
    },
    bullet: activ,
    activ: true,
    courseImg: curs8,
    banner: banner,
  },
  {
    _id: "6148b5c19f9a33592645077b",
    title: 'Cunoașterea profilului psihologic al adolescentului, premisă pentru asigurarea eficienței activității didactice',
    texts: [
      {
        title: 'ORGANIZATOR SI PARTENERI',
        text: 'Cursul este avizat de Ministerul Educației și dezvoltat în parteneriat cu Casa Corpului Didactic București.' +
          'Înscrierea și participarea la curs sunt gratuite',
      },
      {
        title: 'CUI SE ADRESEAZĂ',
        text: 'Tuturor cadrelor didactice din învățământul gimnazial și liceal.',
      },
    ],
    details: [
      {
        title: 'CUI SE ADRESEAZĂ',
        text: 'Tuturor cadrelor didactice din învățământul gimnazial și liceal.',
      },
      {
        title: 'SCOP',
        text: 'Cursul are ca scop abilitarea cadrelor didactice pentru corelarea activității didactice cu profilul psihologic al adolescentului.',
      },
      {
        title: 'DURATĂ',
        text: 'Cursul are o durată de 24 de ore și se va desfășura în sistem blended learning: 6 ore webinar sincron și 18 ore online individual. După parcurgerea cu succes a tuturor modulelor, profesorii vor primi adeverințe emise de Casa Corpului Didactic București, care vor servi la completarea dosarului de formare profesională a cadrelor didactice.',
      },
      {
        title: 'COMPETENȚE VIZATE',
        text: '<ul>' +
          '<li>Cunoașterea caracteristicilor nivelului de dezvoltare - adolescența;</li>' +
          '<li>Cunoașterea specificului învățării la vârsta adolescenței;</li>' +
          '<li>Adaptarea activității didactice la caracteristicile nivelului de dezvoltare-adolescența pentru creșterea eficienței.</li>' +
          '</ul>',
      },
      {
        title: 'CALENDAR',
        text: '<b>1-15 februarie 2023</b>:  Înscrierea la curs<br />' +
          '<b>20-24 februarie 2023</b>: repartizarea pe serii pentru întâlnirile sincron <br />' +
          '*Cursul se va organiza pentru o grupă de minimum 25 de cadre didactice înscrise.',
      },
      {
        title: 'ORGANIZATOR SI PARTENERI',
        text: 'Cursul este avizat de Ministerul Educației și dezvoltat în parteneriat cu Casa Corpului Didactic București. <br />' +
          'Înscrierea și participarea la curs sunt gratuite',
      },
    ],
    durata: '24 ore',
    link: 'cunoasterea-profilului-psihologic-al-adolescentului',
    detalii: {
      trainer: '',
      materiale: '',
      teste: '',
      perioadaInscriere: '<div>1-28 februarie 2023</div>',
      locuri: '500',
      durata: '24 ore',
      pret: 'gratuit',
    },
    bullet: activ,
    activ: true,
    courseImg: curs7,
    banner: banner,
  },
  {
    _id: '604a2f7273c4f9bf838f6b23',
    title: 'Adaptarea activităților online la stilul de învățare al școlarilor mici',
    texts: [
      {
        title: 'ORGANIZATOR SI PARTENERI',
        text: 'Cursul este avizat de Ministerul Educației și dezvoltat în parteneriat cu Casa Corpului Didactic București. ' +
          'Înscrierea și participarea la curs sunt gratuite',
      },
      {
        title: 'CUI SE ADRESEAZĂ',
        text: 'Tuturor cadrelor didactice din învățământul primar.',
      },
    ],
    details: [
      {
        title: 'CUI SE ADRESEAZĂ',
        text: 'Tuturor cadrelor didactice din învățământul primar.',
      },
      {
        title: 'SCOP',
        text: 'Cursul are ca scop abilitarea cadrelor didactice din învățământul primar pentru adaptarea activităților online la stilurile de învățare ale elevilor pentru creșterea eficienței procesului de predare-învățare.',
      },
      {
        title: 'DURATĂ',
        text: 'Cursul are o durată de 24 de ore și se va desfășura în sistem blended learning: 6 ore webinar sincron și 18 ore online individual. După parcurgerea cu succes a tuturor modulelor, profesorii vor primi adeverințe emise de Casa Corpului Didactic București, care vor servi la completarea dosarului de formare profesională a cadrelor didactice.',
      },
      {
        title: 'COMPETENȚE VIZATE',
        text: '<ul>' +
          '<li>Conștientizarea importanței cunoașterii stilurilor de învățare ale elevilor pentru eficiența procesului de predare-învățare;</li>' +
          '<li>Identificarea dificultăților în învățare datorate stilurilor de învățare diferite;</li>' +
          '<li>Adaptarea activităților online la stilurile de învățare ale elevilor.</li>' +
          '</ul>',
      },
      {
        title: 'CALENDAR',
        text: '<b>1-15 februarie 2023</b>:  Înscrierea la curs<br />' +
          '<b>20-24 februarie 2023</b>: repartizarea pe serii pentru întâlnirile sincron <br />' +
          '*Cursul se va organiza pentru o grupă de minimum 25 de cadre didactice înscrise.',
      },
      {
        title: 'ORGANIZATOR SI PARTENERI',
        text: 'Cursul este avizat de Ministerul Educației și dezvoltat în parteneriat cu Casa Corpului Didactic București. <br />' +
          'Înscrierea și participarea la curs sunt gratuite',
      },
    ],
    durata: '24 ore',
    link: 'adaptarea-activitatilor-online',
    detalii: {
      trainer: '',
      materiale: '',
      teste: '',
      perioadaInscriere: '<div>1-28 februarie 2023</div>',
      locuri: '500',
      durata: '24 ore',
      pret: 'gratuit',
    },
    bullet: activ,
    activ: true,
    courseImg: curs6,
    banner: banner,
  },
  {
    _id: '5eb7fe5ab314c9eb599b1eb1',
    title: 'Provocări și soluții în educația digitală',
    texts: [
      {
        title: 'ORGANIZATORI',
        text: 'Casa Corpului Didactic București',
      },
      {
        title: 'PARTENERI',
        text: 'Educațional Digital',
      },
      {
        title: 'CUI SE ADRESEAZĂ',
        text: 'Tuturor profesorilor din învățământul preuniversitar.',
      },
    ],
    details: [
      {
        title: '',
        text: 'În această perioadă, în care învățarea online a dobândit o nouă dimensiune și a căpătat statut prioritar față de învățământul clasic, cu toții avem nevoie de informații, tehnici și strategii specifice. Tehnologia schimbă școala, iar educația online provoacă atât elevii cât și profesorii să se adapteze cu rapiditate la nou.<br>' +
          'Cursul ”<b>Provocări și soluții în educația digitală</b>” oferă cadrelor didactice acces la un set de tehnici de management al orelor de școală online, de motivare a elevilor, precum și strategii digitale pentru activitățile didactice.',
      },
      {
        title: 'CUI SE ADRESEAZĂ',
        text: 'Tuturor profesorilor din învățământul preuniversitar.',
      },
      {
        title: 'DURATĂ',
        text: 'Cursul are o durată de 24 de ore și se va desfășura în sistem blended learning: 6 ore webinar sincron și 18 ore online individual. După parcurgerea cu succes a tuturor modulelor, profesorii vor primi adeverințe emise de Casa Corpului Didactic București, care vor servi la completarea dosarului de formare profesională a cadrelor didactice.',
      },
      {
        title: 'CALENDAR',
        text: '<b>1-15 februarie 2023</b>:  Înscrierea la curs<br />' +
          '<b>20-24 februarie 2023</b>: repartizarea pe serii pentru întâlnirile sincron <br />' +
          '*Cursul se va organiza pentru o grupă de minimum 25 de cadre didactice înscrise.',
      },
      {
        title: 'ORGANIZATOR SI PARTENERI',
        text: 'Cursul este furnizat de Casa Corpului Didactic București în parteneriat cu Educațional Digital' +
          '<br />' +
          'Înscrierea și participarea la curs sunt <b>gratuite</b>',
      },
    ],
    durata: '15 ore',
    link: 'provocari-si-solutii-in-educatia-digitala',
    detalii: {
      trainer: '',
      materiale: '',
      teste: '',
      perioadaInscriere: '<div>1-28 februarie 2023</div>',
      locuri: '500',
      durata: '15 ore',
      pret: 'gratuit',
    },
    bullet: activ,
    activ: true,
    courseImg: curs5,
    banner: bannerCursDictie,
  },
  {
    _id: '5decd54bd9c56fbf3558b991',
    title: 'Dicție și mișcare scenică pentru dezvoltarea personală a copiilor',
    texts: [
      {
        title: 'ORGANIZATORI',
        text: 'Litera Educațional',
      },
      {
        title: 'PARTENERI',
        text: 'Casa Corpului Didactic, Clubul Profesorilor din România',
      },
      {
        title: 'CUI SE ADRESEAZĂ',
        text: 'Tututor cadrelor didactice din învățământul preșcolar și primar.',
      },
    ],
    details: [
      {
        title: '',
        text: 'Cursul online "Dicție și mișcare scenică pentru dezvoltarea personală a copiilor" își propune să ofere profesorilor metode și tehnici de formare și dezvoltare armonioasă a personalității celor mici. Aceste metode vor fi prezentate prin joc, cel mai relevant context de învățare care contribuie la dezvoltarea fizică, psihică și de exprimare a copiilor.',
      },
      {
        title: 'CUI SE ADRESEAZĂ',
        text: 'Tututor cadrelor didactice din învățământul preșcolar și primar.',
      },
      {
        title: 'SCOP',
        text: 'Scopul cursului este de a oferi profesorilor instrumente practice, cu ajutorul cărora să îmbunătățească dicția copiilor, sa le stimuleze spiritul de observație, de memorare, de improvizație și de spontaneitate.',
      },
      {
        title: 'DURATĂ',
        text: 'Cursul are o durată de 24 de ore și se va desfășura în sistem blended learning: 6 ore webinar sincron și 18 ore online individual. După parcurgerea cu succes a tuturor modulelor, profesorii vor primi adeverințe emise de Casa Corpului Didactic București, care vor servi la completarea dosarului de formare profesională a cadrelor didactice.',
      },
      {
        title: 'CALENDAR',
        text: '<b>1-15 februarie 2023</b>:  Înscrierea la curs<br />' +
          '<b>20-24 februarie 2023</b>: repartizarea pe serii pentru întâlnirile sincron <br />' +
          '*Cursul se va organiza pentru o grupă de minimum 25 de cadre didactice înscrise.',
      },
      {
        title: 'ORGANIZATOR SI PARTENERI',
        text: 'Cursul este avizat de Ministerul Educației și furnizat de Casa Corpului Didactic București în parteneriat cu Educațional Digital.\n' +
          '<br />' +
          'Înscrierea și participarea la curs sunt <b>gratuite</b>',
      },
    ],
    durata: '15 ore',
    link: 'curs-stiluri-de-invatare',
    detalii: {
      perioadaInscriere: '<div>1-28 februarie 2023</div>',
      trainer: '',
      materiale: '',
      teste: '',
      locuri: 'nelimitat',
      durata: '15 ore',
      pret: 'gratuit',
    },
    trainers: [
      {
        title: 'Silvia BORȚEANU',
        text: '<div>' +
          '<img src="https://cursuridigitale.ro/images/SilviaBorteanu.jpg" alt="Gabriela Barbulescu" />' +
          '<p>Profesor metodist, CCD București.</p>' +
          '<p>Membru în Comisia pentru omologarea mijloacelor de învăţământ pentru învățământul preșcolar</p>' +
          '<p>Formator</p>' +
          '<p>Autor pentru programe, auxiliare didactice pentru învățământul preșcolar, ghiduri pentru cadre didactice</p>' +
          '</div>',
      },
      {
        title: 'Mădălina RADU',
        text: '<div>' +
          '<img src="https://cursuridigitale.ro/images/MadalinaRadu.jpg" alt="Gabriela Barbulescu" />' +
          '<p>Consilier școlar în cadrul CMBRAE/CMBAP.</p>' +
          '<p>Autor de manuae școlare pentru disciplinele Educație socială și Consiliere și dezvoltare personală.</p>' +
          '<p>Autor auxiliare pentru învățământul preșcolar și primar.</p>' +
          '<p>Formator.</p>' +
          '</div>',
      },
      {
        title: 'Gabriela BĂRBULESCU',
        text: '<div>' +
          '<img src="https://cursuridigitale.ro/images/GabrielaBarbulescu.jpg" alt="Gabriela Barbulescu" />' +
          '<p>Profesor metodist CCD București, coordonator aria curriculară Învăţământ primar.</p>' +
          '<p>Psiholog școlar, CMBRAE.</p>' +
          '<p>Formator de formatori.</p>' +
          '<p>Autor de programe, manuale și auxiliare școlare pentru învățământul primar, ghiduri pentru cadre didactice și părinți.</p>' +
          '<p>Mentor pentru practică pedagogică</p>' +
          '</div>',
      },
    ],
    bullet: activ,
    activ: true,
    courseImg: curs1,
    banner: bannerCursDictie,
  },
  {
    _id: '5de8e6f7476e96f4313a86be',
    title: 'Utilizarea responsabilă a resurselor educaționale digitale',
    texts: [
      {
        title: 'ORGANIZATORI',
        text: 'Litera Educațional',
      },
      {
        title: 'PARTENERI',
        text: 'Casa Corpului Didactic, Clubul Profesorilor din România.',
      },
      {
        title: 'CUI SE ADRESEAZĂ',
        text: 'Tuturor profesorilor din învățământul gimnazial.',
      },
    ],
    details: [
      {
        title: '',
        text: 'În cadrul cursului online Utilizarea responsabilă a resurselor educaționale digitale, aceștia vor afla toate noutățile despre manualele digitale și vor putea accesa gratuit materiale unde le este explicat, pas cu pas, modul de utilizare a acestora: informații tehnice despre suporturile pe care le pot folosi, accesarea conținutului propriu-zis, explicații despre beneficiile aduse de instrumentele digitale etc.' +
          '<br />' +
          '<br />' +
          'De asemenea, în cadrul cursului sunt prezintate o serie de instrumente digitale moderne, cu ajutorul cărora profesorii pot desfășura activități extracurriculare cu elevii. Acestea dezvoltă abilități complementare competențelor dobândite în școală.',
      },
      {
        title: 'CUI SE ADRESEAZĂ',
        text: 'Tuturor profesorilor din învățământul gimnazial.',
      },
      {
        title: 'DURATA',
        text: 'Cursul este structurat pe două module și are o durată de 15 ore. După parcurgerea cu succes a modulelor veți primi adeverință de participare.',
      },
      /*
            {
              title: 'DESCRIERE',
              text: 'În cadrul cursului Resurse digitale, profesorii pot afla: \n' +
                '<ul>' +
                '<li>Tot ce trebuie să știe despre manualele digitale și utilizarea lor la clasă</li>' +
                '<li>Cum se aleg manualele aprobate MEN folosind platforma manuale.edu.ro</li>' +
                '<li>Intrumente digitale moderne pentru activități extracurrilculare</li>' +
                '</ul>' +
                '<p>În cadrul cursului, profesorii vor afla toate noutățile despre manualele digitale și vor putea accesa gratuit materiale unde le este explicat, pas cu pas, modul de utilizare a acestora: informații tehnice despre suporturile pe care le pot folosi, accesarea conținutului propriu-zis, explicații despre beneficiile aduse de instrumentele digitale etc.</p>' +
                '<p>În cadrul cursului vor fi prezentați și pașii pe care un profesor trebuie să îi parcurgă pentru a alege un manual aprobat de Ministerul Educației Naționale, utilizând platforma manuale.edu.ro.</p>' +
                '<p>Ultimul modul al cursului prezintă o serie de instrumente digitale moderne, cu ajutorul cărora profesorii pot desfășura activități extracurriculare cu elevii. Acestea dezvoltă abilități complementare competențelor dobândite în școală.</p>',
            }
      */
    ],
    durata: '15 ore',
    link: 'curs-resurse-digitale-pentru-profesori',
    detalii: {
      perioadaInscriere: '<div>1-28 februarie 2023</div>',
      trainer: '',
      materiale: '',
      teste: '',
      locuri: 'nelimitat',
      durata: '15 ore',
      pret: 'gratuit',
    },
    bullet: activ,
    activ: true,
    courseImg: curs4,
  },
  {
    _id: '',
    title: 'Curs Stiluri de invățare. Stiluri de predare',
    texts: [
      {
        title: 'ORGANIZATORI',
        text: 'Litera Educațional, Clubul Profesorilor din România, Casa Corpului Didactic București',
      },
      {
        title: 'CUI SE ADRESEAZĂ',
        text: 'Tuturor profesorilor, din învățământul preuniversitar, indiferent de specialitate, vechime sau grad',
      },
      {
        title: 'SCOP',
        text: 'Abilitarea cadrelor didactice pentru adaptarea stilului de predare la stilurile de învățare ale elevilor în vederea creșterii eficienței procesului de predare-învățare.',
      },
    ],
    durata: '15 ore',
    link: 'curs-stiluri-de-invatare',
    detalii: {
      trainer: 'ION IONESCU',
      materiale: '233',
      teste: '332',
      locuri: 'nelimitat',
      durata: '4 ore',
      pret: 'gratuit',
    },
    bullet: activ,
    activ: false,
    courseImg: curs2,
  },
  // {
  //   title: 'Curs manual digital',
  //   texts: [
  //     {
  //       title: 'ORGANIZATORI',
  //       text: 'Litera Educațional, Clubul Profesorilor din România, Casa Corpului Didactic București',
  //     },
  //     {
  //       title: 'CUI SE ADRESEAZĂ',
  //       text: 'Profesorilor de gimnaziu',
  //     },
  //     {
  //       title: 'SCOP',
  //       text: 'Profesorii vor afla tot ce au nevoie să știe despre manualele digitale și despre utilizarea lor în lucrul la clasă. În cadrul cursului,aceștia vor putea accesa gratuit materiale unde le este explicat pas cu pas modul de utilizare a manualelor digitale: de la informații tehnice despre suporturile pe care le pot folosi, până la accesarea conținutului propriu-zis, cu explicații despre beneficiile digitale. Ultimul modul al cursului prezintă pașii pe care un profesor trebuie să îi parcurgă pentru a alege un manual aprobat de Ministerul Educației Naționale, utilizând platforma manuale.edu.ro.',
  //     },
  //   ],
  //   durata: '20 ore',
  //   link: 'curs-manual-digital',
  //   detalii: {
  //     trainer: 'ION IONESCU',
  //     materiale: '233',
  //     teste: '332',
  //     locuri: 'nelimitat',
  //     durata: '4 ore',
  //     pret: 'gratuit',
  //   },
  //   bullet: incheiat,
  //   activ: false,
  //   courseImg: curs3,
  // },
  {
    "_id": "63e0d466403bdd92ac565a7e",
    "link": "puterea-creierului",
    "title": "25 de moduri de a crește puterea creierului",
    texts: [
      {
        title: 'DESCRIERE',
        text: 'În creierul uman rezidă potențialul de geniu. Acesta așteaptă ca proprietarul său să-l descopere și să-l folosească. Cursul este structurat în două module: \n' +
          'Modulul 1: Cunoaște cele 25 de moduri prin care poți crește puterea creierului\n' +
          'Modulul 2: Obiectivul primar - stimulează-ți puterea\n',
      }
    ],
    details: [
      {
        title: 'DESCRIERE',
        text: '<p>În creierul uman rezidă potențialul de geniu. Acesta așteaptă ca proprietarul său să-l descopere și să-l folosească. Cursul este structurat în două module: </p>' +
          '<p><b>Modulul 1:</b> Cunoaște cele 25 de moduri prin care poți crește puterea creierului</p>' +
          '<p><b>Modulul 2:</b> Obiectivul primar - stimulează-ți puterea</p>',
      },
      {
        title: 'DURATĂ',
        text: 'Cursul se desfășoară online și are durata de 1 oră.',
      },
      {
        title: 'CALENDAR',
        text: '<b>1-15 februarie 2023</b> perioada de înscriere<br />' +
          '<b>16 februarie - 15 martie 2023</b> perioada de parcurgere a cursului.',
      },
    ],
    durata: 'o oră',
    detalii: {
      trainer: '',
      materiale: '',
      teste: '',
      perioadaInscriere: '<div>1-28 februarie 2023</div>',
      locuri: '500',
      durata: 'o oră',
      pret: 'gratuit',
    },
    bullet: inscriereActiva,
    activ: true,
    courseImg: curs9,
    banner: banner,
  },
  {
    "_id": "63e0d4b50be39f92dd95a98f",
    "link": "cum-sa-predai-cu-incredere-in-timpuri-dificile",
    "title": "Cum să predai cu încredere în timpuri dificile",
    texts: [
      {
        title: 'DESCRIERE',
        text: '<p>Ghidul este conceput pentru a sprijini profesorii, precum și mentorii, terapeuții și chiar părinții care se luptă cu tranziția de la predarea cu prezență fizică - la predarea online.</p>' +
          '<p>Acest program antrenează calmul și rezistența, reconectând profesorii la esența predării.</p>',
      }
    ],
    details: [
      {
        title: 'DESCRIERE',
        text: '<p>Ghidul este conceput pentru a sprijini profesorii, precum și mentorii, terapeuții și chiar părinții care se luptă cu tranziția de la predarea cu prezență fizică - la predarea online.</p>' +
          '<p>Acest program antrenează calmul și rezistența, reconectând profesorii la esența predării.</p>',
      },
      {
        title: 'DURATĂ',
        text: 'Cursul se desfășoară online și are durata de 1 oră.',
      },
      {
        title: 'CALENDAR',
        text: '<b>1-15 februarie 2023</b> perioada de înscriere<br />' +
          '<b>16 februarie - 15 martie 2023</b> perioada de parcurgere a cursului.',
      },
    ],
    durata: 'o oră',
    detalii: {
      trainer: '',
      materiale: '',
      teste: '',
      perioadaInscriere: '<div>1-28 februarie 2023</div>',
      locuri: '500',
      durata: 'o oră',
      pret: 'gratuit',
    },
    bullet: inscriereActiva,
    activ: true,
    courseImg: curs10,
    banner: banner,
  },
  {
    "_id": "63e0d505c382e692c2d1e510",
    "link": "nlp",
    "title": "Tehnica remarcabilă NLP (Programarea Neuro-Lingvistică)",
    texts: [
      {
        title: 'DESCRIERE',
        text: '<p>O culegere credibilă de recomandări despre cum să comunici eficient și să dezvoltați conexiuni semnificative, dezvoltată de liderul mondial al practicii NLP, Ali Campbell.</p>' +
          '<p>Acest curs vă va oferi modalități uimitoare de a vă programa pentru bine, de a vă conecta cu ceilalți la un nivel mult mai profund și de a găsi pacea și claritatea gândului.</p>',
      }
    ],
    details: [
      {
        title: 'DESCRIERE',
        text: '<p>O culegere credibilă de recomandări despre cum să comunici eficient și să dezvoltați conexiuni semnificative, dezvoltată de liderul mondial al practicii NLP, Ali Campbell.</p>' +
          '<p>Acest curs vă va oferi modalități uimitoare de a vă programa pentru bine, de a vă conecta cu ceilalți la un nivel mult mai profund și de a găsi pacea și claritatea gândului.</p>',
      },
      {
        title: 'DURATĂ',
        text: 'Cursul se desfășoară online și are durata de 4 ore.',
      },
      {
        title: 'CALENDAR',
        text: '<b>1-15 februarie 2023</b> perioada de înscriere<br />' +
          '<b>16 februarie - 15 martie 2023</b> perioada de parcurgere a cursului.',
      },
    ],
    durata: 'o oră',
    detalii: {
      trainer: '',
      materiale: '',
      teste: '',
      perioadaInscriere: '<div>1-28 februarie 2023</div>',
      locuri: '500',
      durata: '4 ore',
      pret: 'gratuit',
    },
    bullet: inscriereActiva,
    activ: true,
    courseImg: curs11,
    banner: banner,
  },
  {
    "_id": "63e0d51a1b6675929c4f937f",
    "link": "copii-care-coopereaza",
    "title": "Copii care cooperează",
    texts: [
      {
        title: 'DESCRIERE',
        text: '<p>Un ghid format din două module, care vă va ajuta să dezvoltați conștient și subconștient practici de conectare cu sine și copiii.</p>' +
          '<p>Veți fi familiarizat cu afirmațiile:<br />' +
          '<li>Creez echilibru între ce e bine pentru mine și ce este mai bine pentru copilul tău.</li>' +
          '<li>Practic răbdarea, dragostea, acceptarea și încurajarea.</li>' +
          '<li>Sunt consecvent în exprimarea dragostei și a sprijinului.</li>' +
          '<li>Învăț copilul, prin propriul exemplu, să fie responsabil, empatic și dedicat.</li></p>',
      }
    ],
    details: [
      {
        title: 'DESCRIERE',
        text: '<p>Un ghid format din două module, care vă va ajuta să dezvoltați conștient și subconștient practici de conectare cu sine și copiii.</p>' +
          '<p>Veți fi familiarizat cu afirmațiile:<br />' +
          '<li>Creez echilibru între ce e bine pentru mine și ce este mai bine pentru copilul tău.</li>' +
          '<li>Practic răbdarea, dragostea, acceptarea și încurajarea.</li>' +
          '<li>Sunt consecvent în exprimarea dragostei și a sprijinului.</li>' +
          '<li>Învăț copilul, prin propriul exemplu, să fie responsabil, empatic și dedicat.</li></p>',
      },
      {
        title: 'DURATĂ',
        text: 'Cursul se desfășoară online și are durata de 1 oră.',
      },
      {
        title: 'CALENDAR',
        text: '<b>1-15 februarie 2023</b> perioada de înscriere<br />' +
          '<b>16 februarie - 15 martie 2023</b> perioada de parcurgere a cursului.',
      },
    ],
    durata: 'o oră',
    detalii: {
      trainer: '',
      materiale: '',
      teste: '',
      perioadaInscriere: '<div>1-28 februarie 2023</div>',
      locuri: '500',
      durata: 'o oră',
      pret: 'gratuit',
    },
    bullet: inscriereActiva,
    activ: true,
    courseImg: curs12,
    banner: banner,
  },
  {
    "_id": "63e0d53e403bdd92ac565a82",
    "link": "pasii-catre-o-viata-echilibrata-si-de-success",
    "title": "Pașii către o viață echilibrată și de succes",
    texts: [
      {
        title: 'DESCRIERE',
        text: 'Ți s-a întâmplat să te confrunti cu tranziții în viață care te dezechilibrează? O viață echilibrată între calea personală și cea profesională este cu adevărat posibilă. Grație acestui ghid - a găsești la un click distanță.',
      }
    ],
    details: [
      {
        title: 'DESCRIERE',
        text: 'Ți s-a întâmplat să te confrunti cu tranziții în viață care te dezechilibrează? O viață echilibrată între calea personală și cea profesională este cu adevărat posibilă. Grație acestui ghid - a găsești la un click distanță.\n',
      },
      {
        title: 'DURATĂ',
        text: 'Cursul se desfășoară online și are durata de 2 ore.',
      },
      {
        title: 'CALENDAR',
        text: '<b>1-15 februarie 2023</b> perioada de înscriere<br />' +
          '<b>16 februarie - 15 martie 2023</b> perioada de parcurgere a cursului.',
      },
    ],
    durata: 'o oră',
    detalii: {
      trainer: '',
      materiale: '',
      teste: '',
      perioadaInscriere: '<div>1-28 februarie 2023</div>',
      locuri: '500',
      durata: 'o oră',
      pret: 'gratuit',
    },
    bullet: inscriereActiva,
    activ: true,
    courseImg: curs13,
    banner: banner,
  },
  {
    "_id": "63e0d54f0be39f92dd95a993",
    "link": "exerseaza-ti-memoria",
    "title": "Exersează-ți memoria!",
    texts: [
      {
        title: 'DESCRIERE',
        text: 'Accesează abilitatea subconștientului de a stimula puterea memoriei tale, prin acest curs dezvoltat în două module. Programează-te pentru un habitat mental echilibrat în care memorezi cu ușurință tot ceea ce îți dorești.',
      }
    ],
    details: [
      {
        title: 'DESCRIERE',
        text: 'Accesează abilitatea subconștientului de a stimula puterea memoriei tale, prin acest curs dezvoltat în două module. Programează-te pentru un habitat mental echilibrat în care memorezi cu ușurință tot ceea ce îți dorești.',
      },
      {
        title: 'DURATĂ',
        text: 'Cursul se desfășoară online și are durata de 1 oră.',
      },
      {
        title: 'CALENDAR',
        text: '<b>1-15 februarie 2023</b> perioada de înscriere<br />' +
          '<b>16 februarie - 15 martie 2023</b> perioada de parcurgere a cursului.',
      },
    ],
    durata: 'o oră',
    detalii: {
      trainer: '',
      materiale: '',
      teste: '',
      perioadaInscriere: '<div>1-28 februarie 2023</div>',
      locuri: '500',
      durata: 'o oră',
      pret: 'gratuit',
    },
    bullet: inscriereActiva,
    activ: true,
    courseImg: curs14,
    banner: banner,
  },
  {
    "_id": "63e0d574c382e692c2d1e514",
    "link": "comunicarea-aici-si-acum",
    "title": "Comunicarea aici și acum",
    texts: [
      {
        title: 'DESCRIERE',
        text: 'Miezul interacțiunilor de succes este comunicarea eficientă. Accesează voința nelimitată a gândirii tale de a depăși obstacolele comunicării. Comunică deschis, astfel încât să fii auzit/înțeles de fiecare dată.',
      }
    ],
    details: [
      {
        title: 'DESCRIERE',
        text: 'Miezul interacțiunilor de succes este comunicarea eficientă. Accesează voința nelimitată a gândirii tale de a depăși obstacolele comunicării. Comunică deschis, astfel încât să fii auzit/înțeles de fiecare dată.',
      },
      {
        title: 'DURATĂ',
        text: 'Cursul se desfășoară online și are durata de 1 oră.',
      },
      {
        title: 'CALENDAR',
        text: '<b>1-15 februarie 2023</b> perioada de înscriere<br />' +
          '<b>16 februarie - 15 martie 2023</b> perioada de parcurgere a cursului.',
      },
    ],
    durata: 'o oră',
    detalii: {
      trainer: '',
      materiale: '',
      teste: '',
      perioadaInscriere: '<div>1-28 februarie 2023</div>',
      locuri: '500',
      durata: 'o oră',
      pret: 'gratuit',
    },
    bullet: inscriereActiva,
    activ: true,
    courseImg: curs15,
    banner: banner,
  },
  {
    "_id": "63e0d63d0be39f92dd95a995",
    "link": "cum-sa-te-reinventezi-in-vremuri-incerte",
    "title": "Cum să te reinventezi în vremuri incerte",
    texts: [
      {
        title: 'DESCRIERE',
        text: '<p>Alătură-te ghidului Carol Ritberger și află care este culoarea personalității tale, astfel încât să te poți cunoaște pe deplin. Valorificând calitățile tale unice, vei învăța cum să îmbrățișezi și să depășești provocările din jurul tău.</p>' +
          '<p>Dezvoltă potențialul interior, depășește îndoielile și frica, reinventează-te!</p>',
      }
    ],
    details: [
      {
        title: 'DESCRIERE',
        text: '<p>Alătură-te ghidului Carol Ritberger și află care este culoarea personalității tale, astfel încât să te poți cunoaște pe deplin. Valorificând calitățile tale unice, vei învăța cum să îmbrățișezi și să depășești provocările din jurul tău.</p>' +
          '<p>Dezvoltă potențialul interior, depășește îndoielile și frica, reinventează-te!</p>',
      },
      {
        title: 'DURATĂ',
        text: 'Cursul se desfășoară online și are durata de 1 oră.',
      },
      {
        title: 'CALENDAR',
        text: '<b>1-15 februarie 2023</b> perioada de înscriere<br />' +
          '<b>16 februarie - 15 martie 2023</b> perioada de parcurgere a cursului.',
      },
    ],
    durata: 'o oră',
    detalii: {
      trainer: '',
      materiale: '',
      teste: '',
      perioadaInscriere: '<div>1-28 februarie 2023</div>',
      locuri: '500',
      durata: 'o oră',
      pret: 'gratuit',
    },
    bullet: inscriereActiva,
    activ: true,
    courseImg: curs16,
    banner: banner,
  }
];
