/**
 * Created by mateimisarca on 2019-06-07
 */

import React, { Component } from 'react';

import img from './../../assets/images/banner_380x240.jpg';

export default class HpAbout extends Component {
  constructor() {
    super();

    this.state = {
      open: false,
    };
  }

  render() {
    const {open} = this.state;

    return (
      <div className="about-us-sec pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              {open ? (
                <div className="about-desc">
                  <h1>Dicție și mișcare scenică pentru dezvoltarea personală a copiilor</h1>
                  <p>Educația timpurie este extrem de valoroasă pentru dezvoltarea capacităților individuale și a
                    comportamentelor sociale ale preșcolarilor. Cursul online &quot;Dicție și mișcare scenică pentru
                    dezvoltarea
                    personală a preșcolarilor&quot; își propune să ofere educatorilor metode și tehnici de formare și
                    dezvoltare
                    armonioasă a personalității celor mici. Aceste metode vor fi prezentate prin joc, cel mai relevant
                    context
                    de învățare care contribuie la dezvoltarea fizică, psihică și de exprimare a copiilor.
                    <br />
                    Scopul cursului este de a oferi educatorilor instrumente practice, cu ajutorul cărora să
                    îmbunătățească
                    dicția copiilor, sa le stimuleze spiritul de observație, de memorare, de improvizație și de
                    spontaneitate.</p>
                  <p>Cursul este structurat pe două module și are o durată de 15 ore. După parcurgerea cu succes a
                    modulelor, educatorii vor primi adeverințe emise de Casa Corpului Didactic București, care vor servi
                    la
                    completarea dosarului de formare profesiononală a cadrelor didactice.</p>
                  <p>Cursul este organizat de Editura Litera Educațional, în colaborare cu Casa Corpului Didactic
                    București și
                    Clubul Profesorilor din România.<br />
                    Înscrierea și participarea la curs sunt gratuite.</p>
                </div>
              ) : (
                <div className="about-desc">
                  <h1>Dicție și mișcare scenică pentru dezvoltarea personală a copiilor</h1>
                  <p>Educația timpurie este extrem de valoroasă pentru dezvoltarea capacităților individuale și a
                    comportamentelor sociale ale preșcolarilor. Cursul online &quot;Dicție și mișcare scenică pentru
                    dezvoltarea
                    personală a preșcolarilor&quot; își propune să ofere educatorilor metode și tehnici de formare și
                    dezvoltare
                    armonioasă a personalității celor mici.
                  </p>
                  <div onClick={() => this.setState({open: !open})}>
                    <a href="#" onClick={e => e.preventDefault()}>
                      [vezi mai mult]
                    </a>
                  </div>
                </div>
              )
              }
            </div>

            <div className="col-md-6">
              <div className="vedio-inner">
                <img src={img} />
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}
